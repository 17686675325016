import React from "react";
import { Badge, Button, CopyValue } from "../../../..";
import StatusIndicator from "../../Components/status";
import { FaInfoCircle } from "react-icons/fa";

function BookingDetails({ pnr, amount, id, status, payment, currency, bookRefNo, travellers }) {
	let totalRefund = payment?.refundBreakup?.reduce((prev, curr) => prev + curr.total, 0);

	return (
		<>
			<div className="relative my-2 border border-dashed">
				<div className="absolute w-8 h-8 rounded-full -left-4 -top-4 bg-light z-1" />
				<div className="absolute w-8 h-8 rounded-full -right-4 -top-4 bg-light z-1" />
			</div>
			{status === "CANCELLED" && (
				<div className="pt-6 flex-center">
					<Badge variant="warning">
						<FaInfoCircle className="mr-1" />
						Refund in process... Details will updated shortly
					</Badge>
				</div>
			)}
			{status === "FAILED" && (
				<div className="pt-6 flex-center">
					<Badge variant="danger">This booking has failed</Badge>
				</div>
			)}
			{status === "PENDING" && (
				<div className="pt-6 flex-center">
					<Badge variant="warning">Booking still in process... This may take a few minutes</Badge>
				</div>
			)}
			<div className="grid grid-cols-2 gap-4 p-6">
				<div className="text-xs font-semibold">
					<div className="text-label">Booking ID</div>
					<div>AIR-{id}</div>
				</div>
				<div className="text-xs font-semibold">
					<div className="text-label">Booking Amount</div>
					<div>
						{currency} {amount}
					</div>
				</div>
				<div className="text-xs font-semibold">
					<div className="text-label">PNR</div>
					<CopyValue>{pnr}</CopyValue>
				</div>
				<div className="text-xs font-semibold">
					<div className="text-label">Booking Ref No</div>
					<CopyValue>{bookRefNo}</CopyValue>
				</div>
				<div className="text-xs font-semibold">
					<div className="text-label">Ticket No.</div>
					<CopyValue>{[...new Set(travellers?.flatMap((traveller) => traveller?.tickets || []))].join(", ")}</CopyValue>
				</div>
				<div className="text-xs font-semibold">
					<div className="mb-1 text-label">Status</div>
					<StatusIndicator status={status} />
				</div>
				{status === "REFUNDED" && (
					<div className="text-xs font-semibold">
						<div className="text-label">Refunded Amount</div>
						<div>
							{currency} {totalRefund}
						</div>
					</div>
				)}
				<div className="col-span-2 text-xs font-semibold">
					<div className="text-label">Baggage</div>
					<p className="uppercase">{travellers?.[0]?.baggages[0]?.split("/")?.join(", ")}</p>
					<p className="mt-1 text-xs font-normal text-secondary">Check ticket for full details</p>
				</div>
			</div>
		</>
	);
}

export default BookingDetails;
