import React from "react";
import { Combobox, Toast, AddTeam } from "../..";
import { useState } from "react";
import { apis, get } from "../../../Utils/axios";
import { TbBuilding } from "react-icons/tb";

function TeamSearch({ onSelect = () => {}, placeholder = "Search Teams", label, selected = {}, className = "", authCode, searchOnly = false }) {
	const [AllTeams, setAllTeams] = useState([]);
	const [Loader, setLoader] = useState();

	const onChange = (query) => {
		if (query) {
			fetchTeams(query);
		} else {
			setAllTeams([]);
		}
	};

	const fetchTeams = (query) => {
		setLoader(true);
		//Use admin api only for admin
		get(authCode ? apis.searchTeamsForOnboarding : apis.getAllTeams, { search: query, page: 1, code: authCode }, (r, e) => {
			if (r) {
				setAllTeams(r.teams);
				setLoader(false);
			} else if (e) {
				console.log(e);
				Toast.handleError(e);
				setLoader(false);
			}
		});
	};

	return (
		<div className="flex items-end w-full gap-2">
			<Combobox
				loading={Loader}
				label={label}
				placeholder={placeholder}
				className={className}
				onChange={onChange}
				options={AllTeams.map((team) => ({
					...team,
					label: team.name,
					value: team.id,
					office: team.office?.name,
				}))}
				renderItem={(option, active, selected) => (
					<li className={`${active ? "bg-light" : "bg-white"}`}>
						<div>{option.label}</div>
						<div className="gap-2 align-center">
							<TbBuilding /> {option.office}
						</div>
					</li>
				)}
				selected={selected}
				onSelect={onSelect}
			/>
			{!searchOnly && <AddTeam btnLabel="+ New" />}
		</div>
	);
}

export default TeamSearch;
