import React from "react";
import StatusIndicator from "./status";
import { Button, CopyValue } from "../../..";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FiArrowRight } from "react-icons/fi";
import { getCabinClassCode } from "Utils/utils";

function FlightBookingStatus({ booking = {} }) {
	const NAVIGATE = useNavigate();
	const { id, departureCode, departureTime, arrivalCode, type, status, approvalRequest, adminRequest, returnDate, cabinClass } = booking;

	const searchFlight = () => {
		if (type === "roundtrip") {
			NAVIGATE(
				`/app/travel/flights/round?fromCity=${departureCode}&from=${departureCode}&fromCode=${departureCode}&toCity=${arrivalCode}&to=${arrivalCode}&toCode=${arrivalCode}&date=${moment.utc(departureTime).startOf("day").valueOf()}&returnDate=${moment.utc(returnDate).startOf("day").valueOf()}&adult=${1}&child=${0}&infant=${0}&cabinClass=${getCabinClassCode(cabinClass)}&approvalId=${id}`
			);
		} else {
			NAVIGATE(
				`/app/travel/flights/oneway?fromCity=${departureCode}&from=${departureCode}&fromCode=${departureCode}&toCity=${arrivalCode}&to=${arrivalCode}&toCode=${arrivalCode}&date=${moment.utc(departureTime).startOf("day").valueOf()}&adult=${1}&child=${0}&infant=${0}&cabinClass=${getCabinClassCode(cabinClass)}&approvalId=${id}`
			);
		}
	};

	return (
		<div className="p-2 border-t flex-center-between bg-whitish">
			{approvalRequest && status === "APPROVED" ? (
				<Button onClick={searchFlight} variant="primary" className="btn-xs">
					Book Now <FiArrowRight className="ml-1" />
				</Button>
			) : (
				<CopyValue className="text-xxs">
					{approvalRequest ? "AIR/APP" : adminRequest ? "AIR/REQ" : "AIR"}-{id}
				</CopyValue>
			)}
			<StatusIndicator status={status} />
		</div>
	);
}

export default FlightBookingStatus;
