import React, { useState } from "react";
import { Badge, Button, Modal, OutOfPolicyBadge } from "../../../../../../../../Components";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";

function Fare({ selected, flight, fare, familyFare, onSelect, approval }) {
	const { checkUserPermission } = useUserConfig();
	const approvedAmount = approval?.amount;
	const { fareKey, price, inPolicy, violations, currency, includedServices, bundledServiceName } = fare;
	const active = selected && selected.selectedFare.fareKey === fareKey && selected.selectedFare.price === price;
	const askApproval = !inPolicy && !checkUserPermission("TA") && (approvedAmount ? price > Number(approvedAmount) : true);

	const [ShowServices, setShowServices] = useState(false);

	if (price > 0)
		return (
			<>
				<div className="flex flex-col justify-between flex-1 h-full gap-2 p-5 m-auto text-center flex-center">
					{askApproval ? <OutOfPolicyBadge violations={[...violations]} /> : <></>}
					{bundledServiceName && <p className="text-sm font-bold uppercase text-nowrap">{bundledServiceName}</p>}
					{includedServices?.length > 0 && (
						<Badge onClick={() => setShowServices(true)} className="text-xs cursor-pointer hover:bg-green-500 hover:text-white">
							Included Perks
						</Badge>
					)}
					<p className="text-lg font-bold text-nowrap">
						{currency} {price}
					</p>
					{onSelect &&
						(active ? (
							<Button onClick={() => onSelect()} className="btn-sm">
								Remove
							</Button>
						) : (
							<Button onClick={() => onSelect(fare)} variant="outlined" className="btn-sm">
								Choose Fare
							</Button>
						))}
				</div>
				<Modal open={ShowServices} onClose={() => setShowServices(false)}>
					<div className="p-4">
						<h3 className="mb-4">🚀 What's included in this fare</h3>
						<ul className="space-y-2">
							{includedServices?.map((service) => (
								<Badge key={service}>{service.replace(/[^\x20-\x7Eáéíóúñ]/g, "")}</Badge>
							))}
						</ul>
					</div>
				</Modal>
			</>
		);
	return null;
}

export default Fare;
