import React from "react";
import { parseTerminalName, StampTo24hr, StampToDate } from "../../../../../Utils/utils";
import { FaPlane } from "react-icons/fa";

function Flights({ flights, airports = {} }) {
	const renderSegment = (segment, i) => {
		return (
			<div key={i} className="grid grid-cols-3 mb-4">
				<div>
					<h1>{segment.departureCode}</h1>
					<div className="text-label whitespace-nowrap">{StampTo24hr(segment.departureTime)}</div>
					<div className="text-label whitespace-nowrap">{StampToDate(segment.departureTime)}</div>
					<div className="text-label">{airports[segment.departureCode]?.name}</div>
					{segment.departureAirport && <div className="text-label whitespace-nowrap">{segment.departureAirport}</div>}
					{segment.departureTerminal && <div className="text-label whitespace-nowrap">{parseTerminalName(segment.departureTerminal)}</div>}
				</div>
				<div className="flex-grow flex-center">
					<div className="w-full">
						<img src={`https://cdn.travelwings.com/globalassets/images/${segment.airline?.toUpperCase()}.jpg`} alt={segment.airline} className="w-6 h-6 m-auto mb-3 rounded-full" />
						<div className="align-center">
							<div className="w-2 h-2 border rounded-full border-dark" />
							<div className="flex-grow border-t border-dotted border-dark" />
							<FaPlane />
							<div className="flex-grow border-t border-dotted border-dark" />
							<div className="w-2 h-2 border rounded-full border-dark" />
						</div>
						<div className="mt-2 text-xs text-center whitespace-nowrap">{segment.duration}</div>
					</div>
				</div>
				<div className="text-end">
					<h1>{segment.arrivalCode}</h1>
					<div className="text-label whitespace-nowrap">{StampTo24hr(segment.arrivalTime)}</div>
					<div className="text-label whitespace-nowrap">{StampToDate(segment.arrivalTime)}</div>
					<div className="text-label">{airports[segment.arrivalCode]?.name}</div>
					{segment.arrivalAirport && <div className="text-label whitespace-nowrap">{segment.arrivalAirport}</div>}
					{segment.arrivalTerminal && <div className="text-label whitespace-nowrap">{parseTerminalName(segment.arrivalTerminal)}</div>}
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="p-6 pb-2">
				{flights?.map(({ segments }, i) => {
					return segments.map((segment) => {
						return renderSegment(segment);
					});
				})}
			</div>
		</>
	);
}

export default Flights;
