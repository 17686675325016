import React, { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, DateRange, LabelValue, Select, Toast } from "../../../../Components";
import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";
import { TbArrowsExchange } from "react-icons/tb";
import AirportSearch from "../../Inputs/AirportSearch";
import { useNavigate } from "react-router-dom";
import { useUserConfig } from "../../../../Hooks/useUserConfig";
import SelectPassengers from "../../Inputs/SelectPassengers";
import moment from "moment";
import { CLASSES } from "Utils/constants";

function FlightSearch({ hideModes, from, to, date, range, passengers, cabinClass, mode }) {
	const NAVIGATE = useNavigate();
	const { role, checkUserPermission } = useUserConfig();
	const isUser = role === "USER";

	const MODES = [
		{ label: "One Way", value: "oneway" },
		{ label: "Round Trip", value: "round" },
	];

	const [Values, setValues] = useState({
		from: from || {},
		to: to || {},
		date: date || moment().valueOf(),
		range: range,
		passengers: passengers || {
			adult: 1,
			child: 0,
			infant: 0,
		},
		cabinClass: cabinClass || CLASSES[0],
		mode: mode || MODES[0],
		optimize: true,
	});

	useEffect(() => {
		//Quick Selection
		if (from) {
			setValues((prev) => ({ ...prev, from }));
		}
		if (to) {
			setValues((prev) => ({ ...prev, to }));
		}
	}, [from, to]);

	const updateValues = (obj) => {
		setValues((prev) => ({ ...prev, ...obj }));
	};

	const switchMode = (option) => {
		if (option.value === "oneway") {
			updateValues({ mode: MODES[0] });
		} else {
			updateValues({ mode: MODES[1] });
		}
	};

	const swapAirports = () => {
		const temp = Values.from;
		updateValues({ from: Values.to, to: temp });
	};

	const searchFlights = () => {
		if (!Values.from?.airportCode) {
			return Toast.error("Please select From airport");
		}
		if (!Values.to?.airportCode) {
			return Toast.error("Please select Tp airport");
		}
		if (Values.mode?.value === "oneway" && !Values.date) {
			return Toast.error("Please select date");
		}
		if (Values.mode?.value === "round" && (!Values.range?.startDate || !Values.range?.endDate)) {
			return Toast.error("Please select travel dates");
		}
		cacheSearch({
			label: `${Values.from?.cityName?.toUpperCase()} to ${Values.to?.cityName?.toUpperCase()}`,
			value: Values,
		});
		if (Values.mode.value === MODES[0].value) {
			NAVIGATE(
				`/app/travel/flights/oneway?fromCity=${Values.from?.cityName}&from=${Values.from?.airportName}&fromCode=${Values.from?.airportCode}&toCity=${Values.to?.cityName}&to=${Values.to?.airportName}&toCode=${Values.to?.airportCode}&date=${Values.date}&adult=${Values.passengers?.adult}&child=${Values.passengers?.child}&infant=${Values.passengers?.infant}&cabinClass=${Values.cabinClass?.value}&optimize=${Values.optimize}`
			);
		} else {
			NAVIGATE(
				`/app/travel/flights/round?fromCity=${Values.from?.cityName}&from=${Values.from?.airportName}&fromCode=${Values.from?.airportCode}&toCity=${Values.to?.cityName}&to=${Values.to?.airportName}&toCode=${Values.to?.airportCode}&date=${Values.range.startDate}&returnDate=${Values.range.endDate}&adult=${Values.passengers?.adult}&child=${Values.passengers?.child}&infant=${Values.passengers?.infant}&cabinClass=${Values.cabinClass?.value}&optimize=${Values.optimize}`
			);
		}
	};

	const cacheSearch = (searchData) => {
		const QUICK_OPTIONS = localStorage.getItem("recent-searches") ? JSON.parse(localStorage.getItem("recent-searches")) : [];
		let updatedOptions = [...QUICK_OPTIONS, searchData];
		//Keep latest 5
		if (updatedOptions.length > 5) updatedOptions = updatedOptions.slice(0, 5);
		//Remove duplicates
		updatedOptions = updatedOptions.filter((option, index, self) => index === self.findIndex((t) => t.label === option.label));
		localStorage.setItem("recent-searches", JSON.stringify(updatedOptions));
	};

	return (
		<div>
			{!hideModes && (
				<div className="flex items-start justify-between mb-4">
					<div className="flex items-center gap-2 max-w-min">
						<LabelValue className="whitespace-nowrap">Search Options</LabelValue>
						<Select variant="outlined" btnClass="btn-sm" onChange={switchMode} selected={Values.mode} options={MODES} />
						<Select variant="outlined" btnClass="btn-sm" onChange={(cabinClass) => updateValues({ cabinClass })} selected={Values.cabinClass} options={CLASSES} />
					</div>
					<Checkbox className="text-nowrap" label="Optimize Results" onChange={() => updateValues({ optimize: !Values.optimize })} checked={Values.optimize} />
				</div>
			)}
			<div className="flex p-4 rounded-md bg-light gap-x-2">
				<div className="flex-grow">
					<AirportSearch selected={Values.from} label="Traveling from" onSelect={(from) => updateValues({ from })} placeholder="Travelling from" icon={<FaPlaneDeparture />} />
				</div>
				{isUser && (
					<div className="p-2 text-2xl rounded-md cursor-pointer flex-center bg-light mt-7">
						<TbArrowsExchange onClick={swapAirports} className="transition-all duration-200 hover:rotate-180" />
					</div>
				)}
				<div className="flex-grow">
					<AirportSearch selected={Values.to} label="Going to" onSelect={(to) => updateValues({ to })} placeholder="Going to" icon={<FaPlaneArrival />} />
				</div>
				{checkUserPermission("TA") && (
					<div className="flex-grow">
						<SelectPassengers
							passengers={Values.passengers}
							onChange={(obj) =>
								updateValues({
									passengers: {
										...Values.passengers,
										...obj,
									},
								})
							}
							label="Passengers"
						/>
					</div>
				)}
				<div className="flex-grow">
					{Values?.mode?.value === "oneway" ? (
						<DatePicker backDateSelection={false} label="Travel Date" setDate={(date) => updateValues({ date })} date={Values.date} />
					) : (
						<DateRange backDateSelection={false} label="Travel Dates" setRange={(range) => updateValues({ range })} range={Values.range} />
					)}
				</div>
				<div className="flex-grow">
					<Button onClick={searchFlights} variant="primary" className="w-full mt-7">
						Find
					</Button>
				</div>
			</div>
		</div>
	);
}

export default FlightSearch;
