import { Button, EditNationalId, EditPassport, LabelValue } from "Components";
import { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { get } from "Utils/axios";
import { passengerTypeFromDob, StampToDate } from "Utils/utils";

export const TravellerCard = ({ mandatoryField, id, isGuest, removeTraveller, airlineCode, updateTraveller, resetErrors }) => {
	const [TravellerDetails, setTravellerDetails] = useState({});

	useEffect(() => {
		fetchDetails();
	}, [id]);

	const fetchDetails = () => {
		if (isGuest) fetchGuest(id);
		else fetchUser(id);
	};

	const fetchUser = (id) => {
		get(`users/${id}`, {}, (r, e) => {
			if (r) {
				setTravellerDetails(r);
				updateTraveller(r);
			}
		});
	};

	const fetchGuest = (id) => {
		get(`guests/${id}`, {}, (r, e) => {
			if (r) {
				setTravellerDetails({ ...r, isGuest: true });
				updateTraveller({ ...r, isGuest: true });
			}
		});
	};

	return (
		<div className="mt-4 border rounded-md fade-in-up">
			<div className="px-4 py-3 flex-center-between bg-light rounded-t-md">
				<div className="flex-grow gap-2 align-center">
					<h5>
						{TravellerDetails.fName} {TravellerDetails.lName} {isGuest ? "(Guest)" : ""}
						{passengerTypeFromDob(TravellerDetails.dob, airlineCode) === "ADULT" && " - Adult"}
						{passengerTypeFromDob(TravellerDetails.dob, airlineCode) === "CHILD" && " - Child"}
						{passengerTypeFromDob(TravellerDetails.dob, airlineCode) === "INFANT" && " - Infant"}
					</h5>
				</div>
				<div className="gap-2 text-label align-center">
					<FiMail />
					{TravellerDetails.email}
					<Button onClick={() => removeTraveller(id, isGuest)} variant="danger" className="btn-xs">
						<FaTrashAlt className="mr-2" />
						Remove
					</Button>
				</div>
			</div>
			<div className="grid grid-cols-3 gap-4 p-4">
				<LabelValue label="Phone">{TravellerDetails.phone}</LabelValue>
				<LabelValue label="Gender">{TravellerDetails.gender}</LabelValue>
				<LabelValue label="Date of Birth">{StampToDate(TravellerDetails.dob)}</LabelValue>
				{mandatoryField.passportValidation && (
					<LabelValue label="Passport (Required)">
						<EditPassport
							reload={() => {
								fetchDetails();
								resetErrors();
							}}
							identification={TravellerDetails.identification}
							userId={isGuest ? undefined : id}
							guestId={isGuest ? id : undefined}
							photo={TravellerDetails.photo}
						/>
					</LabelValue>
				)}
				{mandatoryField.nationalityValidation && (
					<LabelValue label="Government ID (Required)">
						<EditNationalId
							reload={() => {
								fetchDetails();
								resetErrors();
							}}
							identification={TravellerDetails.identification}
							userId={isGuest ? undefined : id}
							guestId={isGuest ? id : undefined}
						/>
					</LabelValue>
				)}
			</div>
		</div>
	);
};
