import React from "react";
import { Button } from "../../../..";
import { FiArrowUpRight } from "react-icons/fi";
import QRCode from "react-qr-code";
import { CHECKIN_URLS } from "../../../../../Utils/constants";

function Additional({ airlines = {}, airlineCode, ticket, isImported, status }) {
	const CodeToUrlMap = {};
	CHECKIN_URLS.forEach(({ code, url }) => {
		CodeToUrlMap[code] = url;
	});

	const webCheckIn = (code) => {
		window.open(CodeToUrlMap[code], "_blank");
	};

	if (status === "failed" || status === "confirming") return null;
	return (
		<>
			<div className="gap-4 p-6 pt-2 flex-center-between">
				{isImported ? (
					<div>
						<h5>Imported Booking</h5>
						<div className="mt-2 text-label">This is an imported booking, cancellation and web check-in are not available</div>
					</div>
				) : (
					<div className="flex flex-col gap-4">
						{Object.keys(airlines).map((code, i) => {
							const airline = airlines[code];
							return (
								<div key={i}>
									<div className="align-center">
										<img src={`https://cdn.travelwings.com/globalassets/images/${airline?.toUpperCase()}.jpg`} alt={airline} className="mr-4 rounded-full w-14 h-14" />
										<div className="whitespace-nowrap">
											<h3>{airline.name}</h3>
											<Button onClick={() => webCheckIn(code)} variant="secondary" className="mt-1 btn-xs">
												Web check-in <FiArrowUpRight className="ml-2" />
											</Button>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
				<div>
					{ticket && (
						<>
							<QRCode size={256} className="w-20 h-20 m-auto !bg-canvas" value={ticket} />
							<div className="mt-2 text-center text-xxs text-secondary">Scan to download</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default Additional;
