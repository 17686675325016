import React, { useState } from "react";
import ProfileMenu from "../ProfileMenu";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Logo, Modal, Button, CopyValue } from "..";
import { useNavigate } from "react-router-dom";
import ProfileSwitch from "./profileSwitch";
import { useClientSettings } from "Hooks/useClientSettings";

function FullSidebar({ options = [], content }) {
	const { support_email, support_phone } = useClientSettings();
	const [showSupport, setShowSupport] = useState(false);
	const NAVIGATE = useNavigate();
	const pathname = window.location.pathname;

	return (
		<>
			<div className="sidebar">
				<div className="flex-1 p-4 overflow-y-auto scrollbar-autohide">
					<ProfileMenu />
					<ProfileSwitch />
					<div className="mt-4 menu">
						{options.map((option, index) => {
							if (typeof option === "string") {
								return (
									<div key={`label-${index}`} className="label">
										{option}
									</div>
								);
							} else {
								return (
									<div key={index}>
										<div className={`item flex justify-between ${pathname.startsWith(option.route) ? "active" : ""}`} onClick={option.route ? () => NAVIGATE(option.route) : option.onClick}>
											<span className="align-center gap-x-4">
												{option.icon}
												{option.label}{" "}
											</span>
											<span>{option.children && <FiChevronDown />}</span>
										</div>
										{option.children?.length > 0 && (
											<div key={`sub-${index}`} className={`sub-items ${pathname.startsWith(option.route) ? "active" : ""}`}>
												{option.children.map((child, i) => {
													return (
														<div key={`child-${i}`} className={`sub-item gap-x-4 ${pathname.startsWith(child.route) ? "active" : ""}`} onClick={child.route ? () => NAVIGATE(child.route) : child.onClick}>
															<div className="flex">
																<FiChevronRight className="mr-4 arrow" />
																{child.icon}
															</div>
															{child.label}
														</div>
													);
												})}
											</div>
										)}
									</div>
								);
							}
						})}
					</div>
				</div>
				<div className="p-2 space-y-2">
					{content}
					<div className="flex justify-center">
						<Logo className="items-center font-bold text-secondary" short={true} dotSize="3" dot="ml-2" />
					</div>
				</div>
				<Button className="m-auto my-3 btn-xs" variant="outlined" onClick={() => setShowSupport(true)}>
					Need Support ?
				</Button>
				<Modal open={showSupport} onClose={() => setShowSupport(false)}>
					<div className="p-8 space-y-2">
						<div className="mb-4">
							<h3 className="font-bold">Reach out to us</h3>
							<p className="text-sm">For any questions or support, please contact us using the following information:</p>
						</div>
						<p>
							<b>Phone</b> <CopyValue>{support_phone}</CopyValue>
						</p>
						<p>
							<b>Email</b> <CopyValue>{support_email}</CopyValue>
						</p>
					</div>
				</Modal>
			</div>
		</>
	);
}

export default FullSidebar;
