import React, { useEffect, useState } from "react";
import { Form, Input, Button, Logo, ErrorMsg, Toast } from "../../Components";
import { apis, post } from "../../Utils/axios";
import { useNavigate } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { useLoginHook } from "../../Hooks/useLoginHook";
import Policy from "../Policy";
import AirplaneImg from "../../Assets/Images/airplane.jpg";
import WhiteLogo from "../../Assets/Images/logo_w.png";
import { AUTH_ACTIONS } from "Redux/Reducers/authorization";
import { useDispatch } from "react-redux";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { IsDev } from "Utils/utils";

function LoginPage() {
	const { login } = useLoginHook();
	const NAVIGATE = useNavigate();
	const dispatch = useDispatch();
	const [Values, setValues] = useState({
		email: "",
		password: "",
	});
	const [Loader, setLoader] = useState();
	const [Error, setError] = useState();
	const [CaptchaToken, setCaptchaToken] = useState();

	useEffect(() => {
		//Clear redux store
		dispatch({ type: AUTH_ACTIONS.logout });
		localStorage.removeItem("token");
		localStorage.removeItem("configuration");
		localStorage.removeItem("authorization");
	}, []);

	const onLogin = (e) => {
		if (!IsDev() && !CaptchaToken) {
			return Toast.error("Recaptcha verification failed");
		}
		setError();
		setLoader(true);
		post(
			apis.loginUsingEmail,
			{
				email: Values.email,
				password: Values.password,
			},
			(r, e) => {
				if (r) {
					login(r);
					setLoader();
				} else if (e) {
					setError(e.response?.data.error);
					setLoader();
				}
			}
		);
	};

	return (
		<>
			<div className="grid h-full p-6 md:grid-cols-2">
				<div className="relative items-end hidden w-full h-full overflow-hidden text-white bg-center bg-no-repeat bg-cover rounded-2xl md:flex" style={{ backgroundImage: `url(${AirplaneImg})` }}>
					<div className="absolute left-0 z-10 flex flex-col justify-between h-full p-16" style={{ background: "linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))" }}>
						<img src={WhiteLogo} alt="Logo" className="max-w-[16rem] mb-20" />
						<div className="space-y-12">
							<div className="text-6xl font-bold">Upgrade your Business Travel</div>
							<div className="mt-4">Supercharged Travel & Expense management system for businesses to easily manage employee reimbursements and travel bookings.</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col h-full">
					<div className="justify-center flex-grow align-center">
						<div className="w-full max-w-xs">
							<div className="flex text-4xl font-bold">
								<FaLock className="mr-2" />
								Log in
							</div>
							<div className="mt-1 text-sm">Enter your credentials to continue</div>
							<div className="flex flex-col mt-10">
								<Form onSubmit={onLogin}>
									<Input value={Values.email} onChange={(email) => setValues((prev) => ({ ...prev, email }))} required label="Email" placeholder="Email" />
									<Input value={Values.password} onChange={(password) => setValues((prev) => ({ ...prev, password }))} required className="mt-2" label="Password" placeholder="Password" type="password" />
									<ErrorMsg e={Error} />
									<div onClick={() => NAVIGATE("/reset")} className="mt-2 text-sm cursor-pointer hover:underline">
										Forgot Password ?
									</div>
									<Button loading={Loader} variant="dark" type="submit" className="w-full mt-4">
										Log in
									</Button>
									{!IsDev() && (
										<GoogleReCaptchaProvider reCaptchaKey="6LfFsvsqAAAAABHs12jgWjWNUaJsngV8qD0Kc6Md" useEnterprise={true}>
											<GoogleReCaptcha onVerify={setCaptchaToken} />
										</GoogleReCaptchaProvider>
									)}
								</Form>

								<div onClick={() => NAVIGATE("/signup")} className="mt-4 text-sm text-center cursor-pointer hover:underline">
									or Create a new account
								</div>
							</div>
						</div>
					</div>
					<Policy className="p-8 mt-6" />
				</div>
			</div>
		</>
	);
}

export default LoginPage;
