import React, { useEffect, useRef, useState } from "react";
import FlightCard from "./Components/flightCard";
import FareCard from "./Components/fareCard";
import Sort from "./Components/sort";
import { classifyTime } from "../../../../../../../Utils/utils";
import { useUserPolicy } from "../../../../../../../Hooks/useUserPolicy";
import UserChoice from "./Components/userChoice";
import HasApproval from "./Components/hasApproval";
import { apis, post } from "Utils/axios";
import { Button, Toast } from "Components";
import { FaArrowDown } from "react-icons/fa";
import moment from "moment";

function FlightResult({ approval, filters = {}, onSelect = () => {}, SelectedFlight, flights = [], fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId }) {
	const { travelPolicy } = useUserPolicy();
	const [Filters, setFilters] = useState(filters);
	const [SortBy, setSortBy] = useState({ type: "price", order: "asc" });
	const { showOutOfPolicyOptions = true } = travelPolicy?.domesticFlight || {};
	const [LoadingFamilyFares, setLoadingFamilyFares] = useState(false);
	const [FamilyFares, setFamilyFares] = useState({});

	const PAGE_LIMIT = 50;
	const [Chunk, setChunk] = useState(PAGE_LIMIT);

	useEffect(() => {
		setFilters(filters);
		setChunk(PAGE_LIMIT);
	}, [filters, SortBy]);

	const loadNextChunk = (prev) => {
		if (prev === flights.length) {
			return;
		}
		if (prev + PAGE_LIMIT > flights.length) {
			setChunk(flights.length);
			return;
		}
		setChunk(prev + PAGE_LIMIT);
	};

	const targetRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) loadNextChunk(Chunk);
			},
			{ root: null, rootMargin: "0px", threshold: 0.2 }
		);

		const currentTarget = targetRef.current;

		if (currentTarget) {
			observer.observe(currentTarget);
		}

		return () => {
			if (currentTarget) {
				observer.unobserve(currentTarget);
			}
		};
	}, [Chunk]);

	const getFamilyFare = async (sid, fare) => {
		setLoadingFamilyFares(fare.fareKey);
		post(
			apis.getFamilyFare,
			{
				from: fromCode,
				to: toCode,
				date: moment(date).format("DD-MMM-YYYY"),
				adult: String(adult),
				child: String(child),
				infant: String(infant),
				cabinClass,
				requestId,
				approvalId,
				flightOptionKey: fare.fareKey,
				flightSearchKey: sid,
			},
			(r, e) => {
				setLoadingFamilyFares(false);
				if (r) {
					//Cache the family fares for the fare key
					setFamilyFares((prev) => ({ ...prev, [fare.fareKey]: r.map((fare) => ({ ...fare, isFamilyFare: true })) }));
				} else if (e) {
					//Use the fare as is
					setFamilyFares((prev) => ({ ...prev, [fare.fareKey]: [fare] }));
				}
			}
		);
	};

	const handleSelect = (selectedFare, flight) => {
		console.log(selectedFare, flight);
		if (selectedFare) {
			onSelect({ ...flight, selectedFare });
		} else {
			onSelect();
		}
	};

	const sortFlights = (prev, next) => {
		if (SortBy.type === "price") {
			const prevPrice = prev.fares[0].price;
			const nextPrice = next.fares[0].price;
			return SortBy.order === "des" ? nextPrice - prevPrice : prevPrice - nextPrice;
		} else if (SortBy.type === "departure") {
			const prevDeparture = prev.segments[0]?.departureTime;
			const nextDeparture = next.segments[0]?.departureTime;
			return SortBy.order === "des" ? moment.utc(nextDeparture).diff(moment.utc(prevDeparture)) : moment.utc(prevDeparture).diff(moment.utc(nextDeparture));
		} else if (SortBy.type === "arrival") {
			const prevArrival = prev.segments[prev.segments.length - 1]?.arrivalTime;
			const nextArrival = next.segments[prev.segments.length - 1]?.arrivalTime;
			return SortBy.order === "des" ? moment.utc(nextArrival).diff(moment.utc(prevArrival)) : moment.utc(prevArrival).diff(moment.utc(nextArrival));
		} else if (SortBy.type === "duration") {
			return SortBy.order === "des" ? moment.utc(next.duration).diff(moment.utc(prev.duration)) : moment.utc(prev.duration).diff(moment.utc(next.duration));
		}
	};

	const filterFlights = (flight) => {
		if (!flight.fares.length) {
			return false;
		}
		const _stops = flight.segments.length - 1;
		const _inPolicy = flight.inPolicy;
		const _fullyRefundable = flight.fares[0].refundable;
		const _airline = flight.segments[0].airlineName;
		const _time = classifyTime(flight.segments[0].departureTime);
		const _price = flight.fares[0].price;

		const { maxPrice, airlines, stops, time, inPolicy, fullyRefundable } = Filters;

		let show = true;

		if (maxPrice && _price - 1 > maxPrice) {
			show = false;
		}
		if (stops.length && !stops.includes(_stops)) {
			show = false;
		}
		if (inPolicy && !_inPolicy) {
			show = false;
		}
		if (fullyRefundable && !_fullyRefundable) {
			show = false;
		}
		if (airlines.length && !airlines.includes(_airline)) {
			show = false;
		}
		if (time.length && !time.includes(_time)) {
			show = false;
		}
		return show;
	};

	const filteredFlights = flights.filter(filterFlights);

	if (!filteredFlights.length) {
		return (
			<div className="flex-col w-full py-20 flex-center">
				<h1 className="mb-12 text-2xl text-center">No Flights found</h1>
			</div>
		);
	}

	return (
		<>
			<div className="sticky top-0 bg-white z-9">
				<UserChoice />
				<HasApproval approval={approval} />
				<Sort sortBy={SortBy} updateSort={setSortBy} />
			</div>
			{filteredFlights
				.sort(sortFlights)
				//Limit results
				.slice(0, Chunk)
				.map((flight, index) => {
					const { segments, fares, duration, inPolicy, familyFares, familyFare, vendor } = flight;
					const cheapestFare = fares.reduce((prev, current) => (prev.price < current.price ? prev : current));
					const highestFare = fares.reduce((prev, current) => (prev.price > current.price ? prev : current));
					const hasFares = flight?.fares.some((fare) => fare.price > 0);
					if ((!inPolicy && showOutOfPolicyOptions === false) || !hasFares) {
						return null;
					}

					let loadingAmadeusFares = LoadingFamilyFares === fares[0].fareKey;

					return (
						<div className="mt-4 border rounded-md shadow-sm bg-canvas">
							<div key={index} className="flex">
								<FlightCard key={index} segments={segments} duration={duration} />
								<div className="flex items-center flex-1 overflow-x-auto border-l divide-x">
									{/* {(familyFare ? familyFares : fares).map((fare, i) => {
										return <FareCard loadFamilyFare={loadFamilyFare} approval={approval} key={i} selected={SelectedFlight} onSelect={(selectedFare) => handleSelect(selectedFare, flight)} {...{ flight, fare, cheapestFare, highestFare, familyFare }} />;
									})} */}
									{familyFare ? (
										//Family Fare
										vendor.toLowerCase() === "verteil" ? (
											//Verteil
											familyFares.map((fare, i) => <FareCard approval={approval} key={i} selected={SelectedFlight} onSelect={(selectedFare) => handleSelect(selectedFare, flight)} {...{ flight, fare, cheapestFare, highestFare, familyFare }} />)
										) : //Amadeus
										vendor.toLowerCase() === "amadeus" ? (
											FamilyFares[fares[0].fareKey] ? (
												FamilyFares[fares[0].fareKey].map((fare, i) => <FareCard approval={approval} key={i} selected={SelectedFlight} onSelect={(selectedFare) => handleSelect(selectedFare, flight)} {...{ flight, fare, cheapestFare, highestFare, familyFare }} />)
											) : (
												<div className="flex flex-col justify-between flex-1 h-full gap-4 p-5 m-auto text-center flex-center">
													<p className="text-sm font-bold text-nowrap">✈️ Multiple Fares available</p>
													<Button loading={loadingAmadeusFares} onClick={() => getFamilyFare(flight.sid, fares[0])} variant="outlined" className="btn-sm">
														Load Fares
													</Button>
												</div>
											)
										) : null
									) : (
										fares.map((fare, i) => <FareCard approval={approval} key={i} selected={SelectedFlight} onSelect={(selectedFare) => handleSelect(selectedFare, flight)} {...{ flight, fare, cheapestFare, highestFare, familyFare }} />)
									)}
								</div>
							</div>
						</div>
					);
				})}
			<div ref={targetRef} className="p-4 mt-4 flex-center">
				{Chunk < flights.length && (
					<Button onClick={() => loadNextChunk(Chunk)} className="gap-2">
						<FaArrowDown />
						Load More
					</Button>
				)}
			</div>
		</>
	);
}

export default FlightResult;
