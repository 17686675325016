import React, { useEffect, useState } from "react";
import { Badge, Button, Modal } from "../../../../../../../../Components";
import { apis, get } from "Utils/axios";
import { FaList } from "react-icons/fa";

function FareRules({ refundable, fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey }) {
	const [Rules, setRules] = useState(null);
	const [Loading, setLoading] = useState(false);
	const [ShowRules, setShowRules] = useState(false);
	const [FareType, setFareType] = useState("");

	useEffect(() => {
		getFareRules(FareType);
	}, [FareType]);

	const getFareRules = (fareType) => {
		if (!fareType) return;
		setShowRules(true);
		setLoading(true);
		get(apis.getFareRules, { from: fromCode?.toUpperCase(), to: toCode?.toUpperCase(), date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey, flightSearchKey, fareType }, (r) => {
			const rules = r.fareRules?.fareRuleResponseBean?.headingWiseList;
			setLoading(false);
			if (!rules && fareType === "MINI") {
				// If no rules are found, get the full fare rules
				getFareRules("FULL");
			}
			setRules(rules);
		});
	};

	return (
		<>
			<h6>Cancellation Policy</h6>
			{refundable ? (
				<div>
					<Badge className="mt-2" variant="success">
						Refundable
					</Badge>
					<p className="mt-2 text-xs text-secondary">This flight is eligible for refund on cancellation, please check the fare rules for more details</p>
				</div>
			) : (
				<Badge className="mt-2" variant="warning">
					Non-Refundable
				</Badge>
			)}
			<Button variant="outlined" onClick={() => setFareType("MINI")} className="w-full mt-2 btn-xs">
				View Fare Rules
			</Button>
			<Modal
				title="Fare Rules"
				onClose={() => {
					setShowRules(false);
					setFareType("");
				}}
				open={ShowRules}
				contentClass="p-6"
			>
				{Loading ? (
					<h5 className="text-center">Loading Fare Rules...</h5>
				) : (
					<div className="space-y-4">
						{Rules?.map((rule, index) => {
							return (
								<div key={index} className="space-y-2">
									<h3 className="underline">{rule.heading}</h3>
									{rule.ruleDescription?.split("--").map((desc, index) => {
										return (
											<p key={index} className="text-xs text-secondary">
												{desc}
											</p>
										);
									})}
									<div>
										{rule.penalityList?.map((penality, index) => {
											const [key, value] = Object.entries(penality)[0];
											return (
												<div key={index} className="flex items-center gap-6">
													<h4 className="w-1/2">
														{index + 1}. {key}
													</h4>
													<p className="text-sm">{value}</p>
												</div>
											);
										})}
									</div>
								</div>
							);
						}) || <h5 className="text-center">No fare rules found</h5>}

						{FareType === "MINI" ? (
							<Button loading={Loading} onClick={() => setFareType("FULL")} variant="outlined" className="w-full gap-4 btn-sm">
								<FaList /> Show Full Fare Rules
							</Button>
						) : (
							<Button loading={Loading} onClick={() => setFareType("MINI")} variant="dark" className="w-full gap-4 btn-sm">
								Minimize Fares
							</Button>
						)}
					</div>
				)}
			</Modal>
		</>
	);
}

export default FareRules;
