import React, { useEffect, useState } from "react";
import { FaArrowRight, FaCheck, FaCheckCircle } from "react-icons/fa";
import { Toast, Badge, Button } from "Components";
import { get } from "Utils/axios";

function SubscriptionPlans({ selected, buttonLabel = "Verify your email", country, loading = false, error = "", onSubmit = () => {}, onCancel = () => {} }) {
	const [SelectedPlan, setSelectedPlan] = useState(selected);
	const [Plans, setPlans] = useState({});

	useEffect(() => {
		fetchPlans();
	}, []);

	const handleSubmit = () => {
		if (!SelectedPlan) {
			Toast.error("Please select a plan");
			return;
		}
		onSubmit(SelectedPlan);
	};

	const fetchPlans = async () => {
		get(`/plans/${country}`, {}, (res) => {
			const plans = {
				BASIC: res.find((plan) => plan.name === "BASIC"),
				PREMIUM: res.find((plan) => plan.name === "PREMIUM"),
				ENTERPRISE: res.find((plan) => plan.name === "ENTERPRISE"),
			};
			setPlans(plans);
		});
	};

	return (
		<div className="flex flex-col items-center justify-center h-full text-center gap-y-10 fade-in-up">
			<div>
				<h1 className="text-4xl font-bold">Choose your plan</h1>
				<div className="mt-2 text-xl font-thin">We will not charge you anything for now, you can cancel anytime</div>
			</div>
			<div className="grid max-w-6xl grid-cols-3 mx-auto mt-8 gap-x-4">
				{Object.values(Plans).map(({ name, subscription, perBooking, currency, teamSize, description }) => {
					const isDisabled = subscription === "CONTACT US";
					return (
						<div key={name} onClick={() => !isDisabled && setSelectedPlan(name)} className={`relative border rounded-xl p-6 flex-grow hover:bg-light ${isDisabled ? "bg-light cursor-not-allowed" : "cursor-pointer"} ${SelectedPlan === name ? "ring-2 ring-primary" : ""}`}>
							<h1 className="text-3xl uppercase">{name}</h1>
							{isDisabled ? (
								<h1 className="mt-2 text-lg">{subscription}</h1>
							) : (
								<h1 className="mt-2 text-lg">
									{currency} {subscription}
								</h1>
							)}
							{!isDisabled && (
								<p className="mt-2 text-sm opacity-70">
									*extra {currency} {perBooking} per booking
								</p>
							)}
							<Badge variant="info" className="mt-2">
								{teamSize}
							</Badge>
							<ul className="flex flex-col mt-6 text-sm text-start text-secondary gap-y-2">
								{[...description?.matchAll(/^h\d+\.\s*(.+)$/gm)]
									?.map((match) => match[1])
									.map((item) => (
										<li className="flex">
											<FaCheck className="mt-1 mr-2" />
											{item}
										</li>
									))}
							</ul>
							{SelectedPlan === name && <FaCheckCircle className="absolute w-6 h-6 top-4 left-4 text-primary" />}
						</div>
					);
				})}
			</div>
			{error && (
				<Badge variant="warning" className="mt-4 !text-base">
					{error}
				</Badge>
			)}
			<div className="flex mx-auto mt-8 gap-x-4">
				<Button loading={loading} onClick={handleSubmit} variant="primary">
					{buttonLabel} <FaArrowRight className="ml-2" />
				</Button>
				<Button onClick={onCancel} variant="secondary">
					Cancel
				</Button>
			</div>
		</div>
	);
}

export default SubscriptionPlans;
