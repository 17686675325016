export const CLASSES = [
	{ label: "Economy", value: "1" },
	{ label: "Premium Economy", value: "2" },
	{ label: "Business", value: "3" },
	{ label: "First Class", value: "4" },
];

export const COUNTRIES = [
	{ label: "Afghanistan", value: "AF", currency: "AFN", phoneCode: "+93" },
	{ label: "Albania", value: "AL", currency: "ALL", phoneCode: "+355" },
	{ label: "Algeria", value: "DZ", currency: "DZD", phoneCode: "+213" },
	{ label: "Andorra", value: "AD", currency: "EUR", phoneCode: "+376" },
	{ label: "Angola", value: "AO", currency: "AOA", phoneCode: "+244" },
	{ label: "Antigua and Barbuda", value: "AG", currency: "XCD", phoneCode: "+1" },
	{ label: "Argentina", value: "AR", currency: "ARS", phoneCode: "+54" },
	{ label: "Armenia", value: "AM", currency: "AMD", phoneCode: "+374" },
	{ label: "Australia", value: "AU", currency: "AUD", phoneCode: "+61" },
	{ label: "Austria", value: "AT", currency: "EUR", phoneCode: "+43" },
	{ label: "Azerbaijan", value: "AZ", currency: "AZN", phoneCode: "+994" },
	{ label: "Bahamas", value: "BS", currency: "BSD", phoneCode: "+1" },
	{ label: "Bahrain", value: "BH", currency: "BHD", phoneCode: "+973" },
	{ label: "Bangladesh", value: "BD", currency: "BDT", phoneCode: "+880" },
	{ label: "Barbados", value: "BB", currency: "BBD", phoneCode: "+1" },
	{ label: "Belarus", value: "BY", currency: "BYN", phoneCode: "+375" },
	{ label: "Belgium", value: "BE", currency: "EUR", phoneCode: "+32" },
	{ label: "Belize", value: "BZ", currency: "BZD", phoneCode: "+501" },
	{ label: "Benin", value: "BJ", currency: "XOF", phoneCode: "+229" },
	{ label: "Bhutan", value: "BT", currency: "BTN", phoneCode: "+975" },
	{ label: "Bolivia", value: "BO", currency: "BOB", phoneCode: "+591" },
	{ label: "Bosnia and Herzegovina", value: "BA", currency: "BAM", phoneCode: "+387" },
	{ label: "Botswana", value: "BW", currency: "BWP", phoneCode: "+267" },
	{ label: "Brazil", value: "BR", currency: "BRL", phoneCode: "+55" },
	{ label: "Brunei", value: "BN", currency: "BND", phoneCode: "+673" },
	{ label: "Bulgaria", value: "BG", currency: "BGN", phoneCode: "+359" },
	{ label: "Burkina Faso", value: "BF", currency: "XOF", phoneCode: "+226" },
	{ label: "Burundi", value: "BI", currency: "BIF", phoneCode: "+257" },
	{ label: "Cabo Verde", value: "CV", currency: "CVE", phoneCode: "+238" },
	{ label: "Cambodia", value: "KH", currency: "KHR", phoneCode: "+855" },
	{ label: "Cameroon", value: "CM", currency: "XAF", phoneCode: "+237" },
	{ label: "Canada", value: "CA", currency: "CAD", phoneCode: "+1" },
	{ label: "Central African Republic", value: "CF", currency: "XAF", phoneCode: "+236" },
	{ label: "Chad", value: "TD", currency: "XAF", phoneCode: "+235" },
	{ label: "Chile", value: "CL", currency: "CLP", phoneCode: "+56" },
	{ label: "China", value: "CN", currency: "CNY", phoneCode: "+86" },
	{ label: "Colombia", value: "CO", currency: "COP", phoneCode: "+57" },
	{ label: "Comoros", value: "KM", currency: "KMF", phoneCode: "+269" },
	{ label: "Congo, Democratic Republic of the", value: "CD", currency: "CDF", phoneCode: "+243" },
	{ label: "Congo, Republic of the", value: "CG", currency: "XAF", phoneCode: "+242" },
	{ label: "Costa Rica", value: "CR", currency: "CRC", phoneCode: "+506" },
	{ label: "Croatia", value: "HR", currency: "HRK", phoneCode: "+385" },
	{ label: "Cuba", value: "CU", currency: "CUP", phoneCode: "+53" },
	{ label: "Cyprus", value: "CY", currency: "EUR", phoneCode: "+357" },
	{ label: "Czech Republic", value: "CZ", currency: "CZK", phoneCode: "+420" },
	{ label: "Denmark", value: "DK", currency: "DKK", phoneCode: "+45" },
	{ label: "Djibouti", value: "DJ", currency: "DJF", phoneCode: "+253" },
	{ label: "Dominica", value: "DM", currency: "XCD", phoneCode: "+1" },
	{ label: "Dominican Republic", value: "DO", currency: "DOP", phoneCode: "+1" },
	{ label: "Ecuador", value: "EC", currency: "USD", phoneCode: "+593" },
	{ label: "Egypt", value: "EG", currency: "EGP", phoneCode: "+20" },
	{ label: "El Salvador", value: "SV", currency: "USD", phoneCode: "+503" },
	{ label: "Equatorial Guinea", value: "GQ", currency: "XAF", phoneCode: "+240" },
	{ label: "Eritrea", value: "ER", currency: "ERN", phoneCode: "+291" },
	{ label: "Estonia", value: "EE", currency: "EUR", phoneCode: "+372" },
	{ label: "Eswatini", value: "SZ", currency: "SZL", phoneCode: "+268" },
	{ label: "Ethiopia", value: "ET", currency: "ETB", phoneCode: "+251" },
	{ label: "Fiji", value: "FJ", currency: "FJD", phoneCode: "+679" },
	{ label: "Finland", value: "FI", currency: "EUR", phoneCode: "+358" },
	{ label: "France", value: "FR", currency: "EUR", phoneCode: "+33" },
	{ label: "Gabon", value: "GA", currency: "XAF", phoneCode: "+241" },
	{ label: "Gambia", value: "GM", currency: "GMD", phoneCode: "+220" },
	{ label: "Georgia", value: "GE", currency: "GEL", phoneCode: "+995" },
	{ label: "Germany", value: "DE", currency: "EUR", phoneCode: "+49" },
	{ label: "Ghana", value: "GH", currency: "GHS", phoneCode: "+233" },
	{ label: "Greece", value: "GR", currency: "EUR", phoneCode: "+30" },
	{ label: "Grenada", value: "GD", currency: "XCD", phoneCode: "+1" },
	{ label: "Guatemala", value: "GT", currency: "GTQ", phoneCode: "+502" },
	{ label: "Guinea", value: "GN", currency: "GNF", phoneCode: "+224" },
	{ label: "Guinea-Bissau", value: "GW", currency: "XOF", phoneCode: "+245" },
	{ label: "Guyana", value: "GY", currency: "GYD", phoneCode: "+592" },
	{ label: "Haiti", value: "HT", currency: "HTG", phoneCode: "+509" },
	{ label: "Honduras", value: "HN", currency: "HNL", phoneCode: "+504" },
	{ label: "Hungary", value: "HU", currency: "HUF", phoneCode: "+36" },
	{ label: "Iceland", value: "IS", currency: "ISK", phoneCode: "+354" },
	{ label: "India", value: "IN", currency: "INR", phoneCode: "+91" },
	{ label: "Indonesia", value: "ID", currency: "IDR", phoneCode: "+62" },
	{ label: "Iran", value: "IR", currency: "IRR", phoneCode: "+98" },
	{ label: "Iraq", value: "IQ", currency: "IQD", phoneCode: "+964" },
	{ label: "Ireland", value: "IE", currency: "EUR", phoneCode: "+353" },
	{ label: "Israel", value: "IL", currency: "ILS", phoneCode: "+972" },
	{ label: "Italy", value: "IT", currency: "EUR", phoneCode: "+39" },
	{ label: "Jamaica", value: "JM", currency: "JMD", phoneCode: "+1" },
	{ label: "Japan", value: "JP", currency: "JPY", phoneCode: "+81" },
	{ label: "Jordan", value: "JO", currency: "JOD", phoneCode: "+962" },
	{ label: "Kazakhstan", value: "KZ", currency: "KZT", phoneCode: "+7" },
	{ label: "Kenya", value: "KE", currency: "KES", phoneCode: "+254" },
	{ label: "Kiribati", value: "KI", currency: "AUD", phoneCode: "+686" },
	{ label: "Kuwait", value: "KW", currency: "KWD", phoneCode: "+965" },
	{ label: "Kyrgyzstan", value: "KG", currency: "KGS", phoneCode: "+996" },
	{ label: "Laos", value: "LA", currency: "LAK", phoneCode: "+856" },
	{ label: "Latvia", value: "LV", currency: "EUR", phoneCode: "+371" },
	{ label: "Lebanon", value: "LB", currency: "LBP", phoneCode: "+961" },
	{ label: "Lesotho", value: "LS", currency: "LSL", phoneCode: "+266" },
	{ label: "Liberia", value: "LR", currency: "LRD", phoneCode: "+231" },
	{ label: "Libya", value: "LY", currency: "LYD", phoneCode: "+218" },
	{ label: "Liechtenstein", value: "LI", currency: "CHF", phoneCode: "+423" },
	{ label: "Lithuania", value: "LT", currency: "EUR", phoneCode: "+370" },
	{ label: "Luxembourg", value: "LU", currency: "EUR", phoneCode: "+352" },
	{ label: "Madagascar", value: "MG", currency: "MGA", phoneCode: "+261" },
	{ label: "Malawi", value: "MW", currency: "MWK", phoneCode: "+265" },
	{ label: "Malaysia", value: "MY", currency: "MYR", phoneCode: "+60" },
	{ label: "Maldives", value: "MV", currency: "MVR", phoneCode: "+960" },
	{ label: "Mali", value: "ML", currency: "XOF", phoneCode: "+223" },
	{ label: "Malta", value: "MT", currency: "EUR", phoneCode: "+356" },
	{ label: "Marshall Islands", value: "MH", currency: "USD", phoneCode: "+692" },
	{ label: "Mauritania", value: "MR", currency: "MRU", phoneCode: "+222" },
	{ label: "Mauritius", value: "MU", currency: "MUR", phoneCode: "+230" },
	{ label: "Mexico", value: "MX", currency: "MXN", phoneCode: "+52" },
	{ label: "Micronesia", value: "FM", currency: "USD", phoneCode: "+691" },
	{ label: "Moldova", value: "MD", currency: "MDL", phoneCode: "+373" },
	{ label: "Monaco", value: "MC", currency: "EUR", phoneCode: "+377" },
	{ label: "Mongolia", value: "MN", currency: "MNT", phoneCode: "+976" },
	{ label: "Montenegro", value: "ME", currency: "EUR", phoneCode: "+382" },
	{ label: "Morocco", value: "MA", currency: "MAD", phoneCode: "+212" },
	{ label: "Mozambique", value: "MZ", currency: "MZN", phoneCode: "+258" },
	{ label: "Myanmar", value: "MM", currency: "MMK", phoneCode: "+95" },
	{ label: "Namibia", value: "NA", currency: "NAD", phoneCode: "+264" },
	{ label: "Nauru", value: "NR", currency: "AUD", phoneCode: "+674" },
	{ label: "Nepal", value: "NP", currency: "NPR", phoneCode: "+977" },
	{ label: "Netherlands", value: "NL", currency: "EUR", phoneCode: "+31" },
	{ label: "New Zealand", value: "NZ", currency: "NZD", phoneCode: "+64" },
	{ label: "Nicaragua", value: "NI", currency: "NIO", phoneCode: "+505" },
	{ label: "Niger", value: "NE", currency: "XOF", phoneCode: "+227" },
	{ label: "Nigeria", value: "NG", currency: "NGN", phoneCode: "+234" },
	{ label: "North Korea", value: "KP", currency: "KPW", phoneCode: "+850" },
	{ label: "North Macedonia", value: "MK", currency: "MKD", phoneCode: "+389" },
	{ label: "Norway", value: "NO", currency: "NOK", phoneCode: "+47" },
	{ label: "Oman", value: "OM", currency: "OMR", phoneCode: "+968" },
	{ label: "Pakistan", value: "PK", currency: "PKR", phoneCode: "+92" },
	{ label: "Palau", value: "PW", currency: "USD", phoneCode: "+680" },
	{ label: "Panama", value: "PA", currency: "PAB", phoneCode: "+507" },
	{ label: "Papua New Guinea", value: "PG", currency: "PGK", phoneCode: "+675" },
	{ label: "Paraguay", value: "PY", currency: "PYG", phoneCode: "+595" },
	{ label: "Peru", value: "PE", currency: "PEN", phoneCode: "+51" },
	{ label: "Philippines", value: "PH", currency: "PHP", phoneCode: "+63" },
	{ label: "Poland", value: "PL", currency: "PLN", phoneCode: "+48" },
	{ label: "Portugal", value: "PT", currency: "EUR", phoneCode: "+351" },
	{ label: "Qatar", value: "QA", currency: "QAR", phoneCode: "+974" },
	{ label: "Romania", value: "RO", currency: "RON", phoneCode: "+40" },
	{ label: "Russia", value: "RU", currency: "RUB", phoneCode: "+7" },
	{ label: "Rwanda", value: "RW", currency: "RWF", phoneCode: "+250" },
	{ label: "Saint Kitts and Nevis", value: "KN", currency: "XCD", phoneCode: "+1" },
	{ label: "Saint Lucia", value: "LC", currency: "XCD", phoneCode: "+1" },
	{ label: "Saint Vincent and the Grenadines", value: "VC", currency: "XCD", phoneCode: "+1" },
	{ label: "Samoa", value: "WS", currency: "WST", phoneCode: "+685" },
	{ label: "San Marino", value: "SM", currency: "EUR", phoneCode: "+378" },
	{ label: "Sao Tome and Principe", value: "ST", currency: "STN", phoneCode: "+239" },
	{ label: "Saudi Arabia", value: "SA", currency: "SAR", phoneCode: "+966" },
	{ label: "Senegal", value: "SN", currency: "XOF", phoneCode: "+221" },
	{ label: "Serbia", value: "RS", currency: "RSD", phoneCode: "+381" },
	{ label: "Seychelles", value: "SC", currency: "SCR", phoneCode: "+248" },
	{ label: "Sierra Leone", value: "SL", currency: "SLE", phoneCode: "+232" },
	{ label: "Singapore", value: "SG", currency: "SGD", phoneCode: "+65" },
	{ label: "Slovakia", value: "SK", currency: "EUR", phoneCode: "+421" },
	{ label: "Slovenia", value: "SI", currency: "EUR", phoneCode: "+386" },
	{ label: "Solomon Islands", value: "SB", currency: "SBD", phoneCode: "+677" },
	{ label: "Somalia", value: "SO", currency: "SOS", phoneCode: "+252" },
	{ label: "South Africa", value: "ZA", currency: "ZAR", phoneCode: "+27" },
	{ label: "South Korea", value: "KR", currency: "KRW", phoneCode: "+82" },
	{ label: "South Sudan", value: "SS", currency: "SSP", phoneCode: "+211" },
	{ label: "Spain", value: "ES", currency: "EUR", phoneCode: "+34" },
	{ label: "Sri Lanka", value: "LK", currency: "LKR", phoneCode: "+94" },
	{ label: "Sudan", value: "SD", currency: "SDG", phoneCode: "+249" },
	{ label: "Suriname", value: "SR", currency: "SRD", phoneCode: "+597" },
	{ label: "Sweden", value: "SE", currency: "SEK", phoneCode: "+46" },
	{ label: "Switzerland", value: "CH", currency: "CHF", phoneCode: "+41" },
	{ label: "Syria", value: "SY", currency: "SYP", phoneCode: "+963" },
	{ label: "Taiwan", value: "TW", currency: "TWD", phoneCode: "+886" },
	{ label: "Tajikistan", value: "TJ", currency: "TJS", phoneCode: "+992" },
	{ label: "Tanzania", value: "TZ", currency: "TZS", phoneCode: "+255" },
	{ label: "Thailand", value: "TH", currency: "THB", phoneCode: "+66" },
	{ label: "Timor-Leste", value: "TL", currency: "USD", phoneCode: "+670" },
	{ label: "Togo", value: "TG", currency: "XOF", phoneCode: "+228" },
	{ label: "Tonga", value: "TO", currency: "TOP", phoneCode: "+676" },
	{ label: "Trinidad and Tobago", value: "TT", currency: "TTD", phoneCode: "+1" },
	{ label: "Tunisia", value: "TN", currency: "TND", phoneCode: "+216" },
	{ label: "Turkey", value: "TR", currency: "TRY", phoneCode: "+90" },
	{ label: "Turkmenistan", value: "TM", currency: "TMT", phoneCode: "+993" },
	{ label: "Tuvalu", value: "TV", currency: "AUD", phoneCode: "+688" },
	{ label: "Uganda", value: "UG", currency: "UGX", phoneCode: "+256" },
	{ label: "Ukraine", value: "UA", currency: "UAH", phoneCode: "+380" },
	{ label: "United Arab Emirates", value: "AE", currency: "AED", phoneCode: "+971" },
	{ label: "United Kingdom", value: "GB", currency: "GBP", phoneCode: "+44" },
	{ label: "United States", value: "US", currency: "USD", phoneCode: "+1" },
	{ label: "Uruguay", value: "UY", currency: "UYU", phoneCode: "+598" },
	{ label: "Uzbekistan", value: "UZ", currency: "UZS", phoneCode: "+998" },
	{ label: "Vanuatu", value: "VU", currency: "VUV", phoneCode: "+678" },
	{ label: "Vatican City", value: "VA", currency: "EUR", phoneCode: "+39" },
	{ label: "Venezuela", value: "VE", currency: "VES", phoneCode: "+58" },
	{ label: "Vietnam", value: "VN", currency: "VND", phoneCode: "+84" },
	{ label: "Yemen", value: "YE", currency: "YER", phoneCode: "+967" },
	{ label: "Zambia", value: "ZM", currency: "ZMW", phoneCode: "+260" },
	{ label: "Zimbabwe", value: "ZW", currency: "ZWL", phoneCode: "+263" },
];

export const CHECKIN_URLS = [
	{
		label: "Spice Jet",
		value: "SG",
		url: "https://www.spicejet.com/#checkin",
	},
	{
		label: "Indigo",
		value: "6E",
		url: "https://www.goindigo.in/web-check-in.html",
	},
	{
		label: "Vistara",
		value: "UK",
		url: "https://www.airvistara.com/in/en/travel-information/vistara-experience/on-ground/check-in/web-check-in",
	},
	{
		label: "Air India",
		value: "AI",
		url: "https://ota.airindia.in/huf/",
	},
	{
		label: "Air Asia",
		value: "I5",
		url: "https://www.airasia.com/check-in/v2/en/gb",
	},
	{
		label: "Air India Express",
		value: "IX",
		url: "https://www.airindiaexpress.com/checkin-home",
	},
	{
		label: "Akasa Air",
		value: "QP",
		url: "https://www.akasaair.com/check-in/web-check-in",
	},
	{
		label: "Go First",
		value: "G8",
		url: "https://www.flygofirst.com/plan-my-trip/web-check-in/",
	},
];

export const toast = {
	success: {
		icon: "✅",
		style: {
			fontSize: "0.8rem",
		},
	},
	error: {
		style: {
			fontSize: "0.8rem",
		},
	},
	loading: {
		icon: (
			<svg className={`animate-spin text-primary h-4 w-4`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
				<circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
				<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
			</svg>
		),
		style: {
			fontSize: "0.8rem",
		},
	},
};

export const fontSizes = {
	small: "14px",
	medium: "16px",
	large: "18px",
};

export const APPROVERS = [
	"Team",
	{
		label: "Team Head 1",
		value: "TH1",
	},
	{
		label: "Team Head 2",
		value: "TH2",
	},
	"Office",
	{
		label: "Office Head 1",
		value: "OH1",
	},
	{
		label: "Office Head 2",
		value: "OH2",
	},
];

export const getWhenConditions = (type) => {
	return [
		{
			value: "AMOUNT",
			label: `amount`,
		},
		{
			value: "IN_POLICY",
			label: `is in Policy`,
		},
		{
			value: "OUT_POLICY",
			label: `out of Policy`,
		},
	];
};

export const APPROVER_CODE_FIELD_MAP = {
	TH1: {
		type: "team",
		field: "head1Id",
		association: "head1",
		label: "Team Head 1",
	},
	TH2: {
		type: "team",
		field: "head2Id",
		association: "head2",
		label: "Team Head 2",
	},
	OH1: {
		type: "office",
		field: "head1Id",
		association: "head1",
		label: "Office Head 1",
	},
	OH2: {
		type: "office",
		field: "head2Id",
		association: "head2",
		label: "Office Head 2",
	},
};
