import React, { useRef } from "react";
import { savePDF } from "@progress/kendo-react-pdf";
import { Button, CopyValue, Toast } from "../Library";
import moment from "moment";
import { parseTerminalName, StampTo24hr, StampToDate } from "../../Utils/utils";
import { FiDownload } from "react-icons/fi";
import Logo from "Components/Logo";

function FlightTicket({ booking = {} }) {
	const TICKET = useRef(null);

	const downloadPDF = () => {
		savePDF(TICKET.current, {
			paperSize: "auto",
			margin: 50,
			fileName: `Flight Ticket`,
		});
		Toast.success("Ticket Downloaded");
	};

	const { flights, passengers, amount, date, maker, pnr, bookRefNo, currency, travellers, support_phone, support_email } = booking;

	const pax = passengers?.map(({ passenger, guest }) => ({
		passenger,
		guest,
		//Map the passenger to the traveller by matching the first and last name
		...travellers?.find((traveller) => {
			const pax = passenger || guest;
			return `${pax?.fName?.trim().toLowerCase()} ${pax?.lName?.trim().toLowerCase()}` === `${traveller?.firstName?.trim().toLowerCase()} ${traveller?.lastName?.trim().toLowerCase()}`;
		}),
	}));
	return (
		<>
			<div ref={TICKET} className="max-w-[900px] min-w-[700px] m-auto text-sm bg-light rounded-3xl">
				<div className="p-12">
					<div className="flex-center-between">
						<Logo className="mb-6" />
						<Button onClick={downloadPDF} variant="outlined" className="gap-2 btn-sm">
							<FiDownload /> Download as PDF
						</Button>
					</div>
					<div className="p-6 text-white rounded-lg bg-dark flex-center-between">
						<div className="text-xl">Flight Booking</div>
						<div>
							<div className="text-lg font-bold">PNR: {pnr}</div>
						</div>
					</div>
					<div className="gap-6 p-6 mt-4 bg-white border rounded-lg">
						<div className="mb-6 text-lg font-bold">Booking Details</div>
						<div className="grid grid-cols-3 gap-4">
							<div>
								<div className="mb-2 font-bold">Booked On</div>
								<div>{moment(date).format("DD MMM YYYY")}</div>
							</div>
							<div>
								<div className="mb-2 font-bold">Booked by</div>
								<div>{maker?.email}</div>
							</div>
							<div className="text-end">
								<div className="mb-2 font-bold">Amount</div>
								<div>
									{currency} {amount}
								</div>
							</div>
							<div className="col-span-3">
								<div className="mb-2 font-bold">Booking Reference No.</div>
								<CopyValue>{bookRefNo}</CopyValue>
							</div>
						</div>
					</div>
					<div className="p-6 mt-6 bg-white border rounded-lg">
						<div className="text-lg font-bold">Flight Details</div>
						{flights?.map(({ segments }) => {
							return segments.map((segment, j) => {
								const { airline, flightNumber, departureCode, duration, departureAirport, departureTime, arrivalCode, arrivalAirport, arrivalTime, departureTerminal, arrivalTerminal } = segment;
								const stops = segments.slice(1).map((segment) => segment.departureCode);

								return (
									<div key={j} className="flex items-start justify-between gap-6 mt-6">
										<div>
											<div className="font-bold">
												{airline}-{flightNumber}
											</div>
											<img src={`https://cdn.travelwings.com/globalassets/images/${airline?.toUpperCase()}.jpg`} alt="Airline" className="w-10 h-10 mt-2 rounded-full" />
										</div>
										<div className="text-xs text-end">
											<div className="mb-2 text-2xl font-bold">{departureCode}</div>
											<div>
												{StampTo24hr(departureTime)}, {StampToDate(departureTime)}
											</div>
											<div>
												{parseTerminalName(departureTerminal)}
												<br />
												{departureAirport}
											</div>
										</div>
										<div className="flex-grow flex-center min-w-sm">
											<div className="w-full mt-1 align-center">
												<div className="w-2 h-2 border rounded-full bg-light" />
												<div className="flex-grow border border-dashed" />
												<div className="px-2 py-1 text-xs text-center border rounded-full bg-light whitespace-nowrap">
													{duration} {stops.length > 0 && ` Via ${stops.join(", ")}`}
												</div>
												<div className="flex-grow border border-dashed" />
												<div className="w-2 h-2 border rounded-full bg-light" />
											</div>
										</div>
										<div className="text-xs">
											<div className="mb-2 text-2xl font-bold">{arrivalCode}</div>
											<div>
												{StampTo24hr(arrivalTime)}, {StampToDate(arrivalTime)}
											</div>
											<div>
												{parseTerminalName(arrivalTerminal)}
												<br />
												{arrivalAirport}
											</div>
										</div>
									</div>
								);
							});
						})}
					</div>
					<div className="p-6 mt-5 bg-white border rounded-lg">
						<div className="text-lg font-bold">{passengers?.length} Traveller(s)</div>
						<table className="w-full mt-6">
							<tbody>
								<tr className="font-bold">
									<td className="p-2">NAME</td>
									<td className="p-2">GENDER</td>
									<td className="p-2">CONTACT</td>
									<td className="p-2">BAGGAGE</td>
									<td className="p-2">TICKET</td>
								</tr>
								{pax?.map((traveller, i) => {
									const { passenger, guest } = traveller;
									const { fName, lName, gender, email, phone, phoneCode } = passenger || guest;
									return (
										<tr key={i} className="text-sm">
											<td className="p-2">
												{fName} {lName}
											</td>
											<td className="p-2">{gender}</td>
											<td className="p-2">
												{email}
												<br />
												{phone}
											</td>
											<td className="p-2">
												{traveller?.baggages?.map((baggage, i) => {
													const baggagesCount = traveller?.baggages?.length;
													return (
														<div key={i} className="">
															{baggagesCount > 1 && i === 0 && <span className="font-medium">ONWARD: </span>}
															{baggagesCount > 1 && i === baggagesCount - 1 && <span className="font-medium">RETURN: </span>}
															{baggage}
														</div>
													);
												})}
											</td>
											<td className="p-2">{[...new Set(traveller?.tickets || [])]?.join(", ")}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>

					<div className="mt-4 space-y-2 text-xs ">
						<h3 className="font-medium">Terms & Conditions</h3>
						<ul className="list-disc list-inside opacity-70">
							<li>We recommend you to make use of TW booking reference number to communicate with Travelwings team about this booking</li>
							<li>Due to traffic situations, we strongly advise that you reach airport 4 hours prior to departure for International flights and 2 hours prior to departure for domestic flights.</li>
							<li>Re-check the passenger name(s) as per the passport/identity proof, departure & arrival date, time, flight number, terminal, baggage details etc.</li>
							<li>In case of International travel, please check your requirements for travel documentation like valid Passport/visa/Ok to Board/travel and medical insurance with the airline and relevant Embassy or Consulate before commencing your journey.</li>
							<li>If you have a connecting flight, you must review the visa requirements for the transit country, including the exemption conditions for visa-free transit.</li>
							<li>The local authorities in certain countries may impose additional taxes (VAT, tourist tax etc.), which generally has to be paid locally.</li>
							<li>Travelwings, cannot be held responsible for any schedule change/flight cancellation by the airline.</li>
							<li>We will not be held responsible for any loss or damage to travelers and his/her belongings due to any accident, theft or other Mishap / Unforeseen circumstances</li>
							<li>Any amendments of the booking will be as per the airline terms and conditions comprising of penalties, fare difference which may change upon subject to availability by the airline.</li>
							<li>Any Cancellation/Refund of booking will be handled from case to case basis depending on the airline terms and conditions.</li>
						</ul>
					</div>

					<div className="mt-4">
						<p className="text-sm">For any amendments, cancellation or ancillary services or support, please contact us using the following information:</p>
						<p>
							<b>Phone</b> {support_phone} <b>Email</b> {support_email}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default FlightTicket;
