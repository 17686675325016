import React, { useState } from "react";
import { Button, Modal, Toast, Violations } from "../../../../../../../../Components";
import { useNavigate } from "react-router-dom";
import FlightItinerary from "./itinerary";
import { useUserPolicy } from "../../../../../../../../Hooks/useUserPolicy";
import { useUserConfig } from "../../../../../../../../Hooks/useUserConfig";
import AskApproval from "./askApproval";
import SendToAdmin from "./sendToAdmin";
import { apis, post } from "../../../../../../../../Utils/axios";
import FlightDetails from "./details";
import FareRules from "./fareRules";
import { useClientSettings } from "Hooks/useClientSettings";
import moment from "moment";
import Thinking from "../../../../../../../../Assets/Images/emojis/thinking.png";
import { FaShieldAlt } from "react-icons/fa";

function Summary({ approval, selectedFlight = {}, journeyKey, passengers = {}, approvalId, requestId, fromCode, toCode, date, returnDate, adult, child, infant, cabinClass }) {
	console.log(selectedFlight);

	const { checkUserPermission } = useUserConfig();
	const { travelPolicy } = useUserPolicy();
	const { currency } = useClientSettings();
	const adminBooking = travelPolicy?.domesticFlight?.adminBooking && !checkUserPermission("TA");
	const approvedAmount = approval?.amount;
	const askApproval = !selectedFlight?.selectedFare?.inPolicy && !checkUserPermission("TA") && (approvedAmount ? selectedFlight?.selectedFare?.price > Number(approvedAmount) : true);

	const segments = selectedFlight?.onwardSegments || selectedFlight?.returnSegments;
	const flightDetails = segments[0];
	const NAVIGATE = useNavigate();
	const [Loading, setLoading] = useState();
	const [ConfirmData, setConfirmData] = useState(false);

	const confirmFare = () => {
		setLoading(true);
		post(
			apis.confirmRoundFlightFare,
			{
				from: fromCode,
				to: toCode,
				date: moment(date).format("DD-MMM-YYYY"),
				returnDate: moment(returnDate).format("DD-MMM-YYYY"),
				adult: String(adult),
				child: String(child),
				infant: String(infant),
				cabinClass,
				requestId,
				approvalId,
				flightOptionKey: selectedFlight?.selectedFare?.fareKey,
				flightSearchKey: selectedFlight?.sid,
				//Bundled Service ID is only available for Amadeus and Verteil
				...(selectedFlight?.selectedFare?.isFamilyFare && { bundledServiceId: selectedFlight?.selectedFare?.bundledServiceId }),
			},
			(r, e) => {
				setLoading(false);
				if (r?.statusMessage === "success") {
					if (r.priceDiffernce > 0.1 || r.priceDiffernce < -0.1) {
						setConfirmData(r);
						return;
					} else {
						moveNext(r);
					}
				} else if (e) {
					console.error(e);
					Toast.error("Unable to book this flight. Please choose another flight");
				}
			}
		);
	};

	const moveNext = (data) => {
		NAVIGATE(`/app/travel/flights/book`, {
			state: {
				fareConfirmDetails: data,
				flight: {
					...data.flight,
					//parse round flight data to oneway like flight data
					segments: data.flight?.onwardSegments,
					returnSegments: data.flight?.returnSegments,
					duration: data.flight?.onwardDuration,
					returnDuration: data.flight?.returnDuration,
				},
				flightOptionKey: data.selectedFlightOptionKey,
				flightSearchKey: selectedFlight?.sid,
				passengers,
				approvalId,
				requestId,
			},
		});
	};

	if (ConfirmData) {
		if (!ConfirmData.inPolicy) {
			return (
				<Modal open={true}>
					<div className="p-6 border rounded-md">
						<p className="flex justify-start gap-4 text-5xl">
							<FaShieldAlt className="text-red-600" />
							<h1>New fare is higher than your policy limit</h1>
						</p>
						<div className="my-6">
							The fare for selected flight has been {ConfirmData.priceDiffernce > 0 ? "increased" : "decreased"} by <b>{Math.abs(ConfirmData.priceDiffernce).toFixed(2)}</b>. The new fare does not fall within your policy and will require approval. You can either ask for approval or
							select another flight.
						</div>
						<Violations violations={ConfirmData?.violations} />
						<div className="flex items-center justify-center gap-2 pt-6">
							<AskApproval {...{ flight: ConfirmData.flight, fare: ConfirmData.flight?.selectedFare, type: "roundtrip", violations: ConfirmData?.violations }} />
							<Button onClick={() => window.location.reload()} variant="dark" className="btn-sm">
								Refresh Options
							</Button>
						</div>
					</div>
				</Modal>
			);
		}

		return (
			<Modal open={true}>
				<div className="p-6 text-center border rounded-md">
					<img src={Thinking} alt="Eyes Emoji" className="w-20 m-auto mb-6" />
					<h1>Fare changed by Airline</h1>
					<div className="mt-2">
						The fare for your booking has been {ConfirmData.priceDiffernce > 0 ? "increased" : "decreased"} by {Math.abs(ConfirmData.priceDiffernce).toFixed(2)}. You can either continue with the new price of {ConfirmData.currentPrice.toFixed(2)} or try searching again.
					</div>
					<div className="flex items-center justify-center gap-2 pt-6">
						<Button onClick={() => moveNext(ConfirmData)} variant="primary" className="btn-sm">
							Continue with new price
						</Button>
						<Button onClick={() => window.location.reload()} variant="dark" className="btn-sm">
							Refresh Options
						</Button>
					</div>
				</div>
			</Modal>
		);
	}

	return (
		<div className="flex flex-col justify-between w-full h-full fade-in-right">
			<div className="flex flex-col gap-6 p-4 overflow-y-scroll">
				<div className="align-center">
					<img src={flightDetails.logo} alt="airline-logo" className="w-10 h-10 mr-4 rounded-full" />
					<h3>{flightDetails.airlineName}</h3>
				</div>
				<div>
					<FlightItinerary onwardSegments={selectedFlight?.onwardSegments} returnSegments={selectedFlight?.returnSegments} />
				</div>
				{selectedFlight?.onwardSegments && (
					<>
						<div>
							<FlightDetails baggage={{ checkin: selectedFlight?.onwardSegments[0].checkinBaggage, cabin: selectedFlight?.onwardSegments[0].cabinBaggage }} />
						</div>
						<div>
							<FareRules {...{ refundable: selectedFlight?.selectedFare?.refundable, fromCode, toCode, date, adult, child, infant, cabinClass, requestId, approvalId, flightOptionKey: selectedFlight?.selectedFare?.fareKey, flightSearchKey: selectedFlight?.sid }} />
						</div>
					</>
				)}
			</div>
			<div className="p-4">
				<div>
					<div className="gap-1 mb-2 text-sm align-center">
						For {passengers.adult} Adult
						{passengers.child > 0 && <>, {passengers.child} Child</>}
						{passengers.infant > 0 && <>, {passengers.infant} Infant</>}
					</div>
					<h1>
						Total {currency} {selectedFlight?.selectedFare?.price}
					</h1>
				</div>
				<div className="mt-4">
					{askApproval ? (
						<AskApproval
							{...{
								flight: selectedFlight,
								fare: selectedFlight?.selectedFare,
								violations: selectedFlight.violations,
								type: "roundtrip",
							}}
						/>
					) : adminBooking ? (
						<SendToAdmin
							{...{
								selectedFlight,
								type: "round",
								approvalId,
							}}
						/>
					) : (
						<Button loading={Loading} variant="primary" onClick={confirmFare} className="w-full">
							Continue
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default Summary;
