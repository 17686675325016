import React, { useEffect, useRef, useState } from "react";
import { TbReceipt } from "react-icons/tb";
import { Button, CloseButton, DatePicker, Divider, CustomFields, Input, Portal, Select, Toast, Form, AnimatedTick } from "../../../../../../Components";
import ExpenseAttachments from "./attachments";
import { apis, get, post } from "../../../../../../Utils/axios";
import useFetch from "../../../../../../Hooks/useFetch";
import moment from "moment";
import { useClientSettings } from "../../../../../../Hooks/useClientSettings";
import { FiPlus } from "react-icons/fi";

function AddReceipt({ onReload = () => {} }) {
	const { isEnabled } = useClientSettings();

	const [Show, setShow] = useState(false);
	const [OCRData, setOCRData] = useState();
	const [RunningOcr, setRunningOcr] = useState(false);
	const [Attachments, setAttachments] = useState([]);
	const [Loading, setLoading] = useState(false);
	console.log(Attachments);

	const initial = {
		merchant: "",
		amount: "",
		date: "",
		description: "",
		category: {},
	};

	const { response, loading } = useFetch(apis.getExpenseCategories, null, { categories: [] });
	const [Success, setSuccess] = useState(false);

	const [Values, setValues] = useState(initial);
	const GET_CUSTOM_FIELDS = useRef();

	useEffect(() => {
		if (Attachments.length === 1) {
			//Run OCR only for first receipt
			runOCR(Attachments[0]);
		}
	}, [Attachments]);

	const updateValues = (obj) => {
		setValues((prev) => ({ ...prev, ...obj }));
	};

	const runOCR = (obj) => {
		setRunningOcr(true);
		get(apis.runOcr, { fileId: obj.file.id }, (r, e) => {
			if (r) {
				setOCRData(r);
				setValues((prev) => ({ ...prev, merchant: r.vendorName, date: r.receiptDate, amount: r.total }));
				setRunningOcr(false);
			} else if (e) {
				setRunningOcr(false);
			}
		});
	};

	const onSubmit = () => {
		if (!Values.merchant) {
			return Toast.error("Please enter expense merchant");
		}
		if (!Values.amount) {
			return Toast.error("Please enter expense amount");
		}
		if (!Values.date) {
			return Toast.error("Please select expense date");
		}
		if (!Values.description) {
			return Toast.error("Please enter expense description");
		}
		if (!Attachments.length) {
			return Toast.error("Please add receipt photo");
		}

		let customFields;
		if (isEnabled("ORGANIZATION")) {
			if (!Values.category?.value) {
				return Toast.error("Please select expense category");
			}

			customFields = GET_CUSTOM_FIELDS.current();
			if (!customFields) {
				return;
			}
		}

		setLoading(true);
		post(
			apis.addReceipt,
			{
				...Values,
				categoryId: Values.category.value,
				customFields,
				attachments: Attachments.map((a) => a.file.key),
			},
			(r, e) => {
				setLoading(false);
				if (r) {
					setValues(initial);
					setAttachments([]);
					setSuccess(true);
					onReload();
				} else if (e) {
					Toast.handleError(e);
				}
			}
		);
	};

	if (Success) {
		return (
			<>
				<Portal>
					<div className="absolute bg-[rgba(0,0,0,0.5)] top-0 left-0 right-0 bottom-0 z-20 flex-center fade-in">
						<div className="p-8 bg-canvas rounded-xl">
							<div className="h-20 flex-center">
								<AnimatedTick />
							</div>
							<div className="mt-4 text-center">
								<h1>Receipt created</h1>
								<div className="mt-4 text-label">
									Expense have been added successfully, <br />
									you can track all your expenses in Expenses section
								</div>
								<div className="gap-2 mt-6 flex-center">
									<Button
										onClick={() => {
											setSuccess(false);
											setShow(false);
										}}
									>
										Close
									</Button>
									<Button variant="primary" onClick={() => setSuccess(false)}>
										Add More
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Portal>
			</>
		);
	}

	return (
		<>
			<Button onClick={() => setShow(true)} variant="primary">
				<FiPlus className="mr-2" />
				Add Receipt
			</Button>
			{Show && (
				<Portal>
					<div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex w-full overflow-hidden bg-light frosted fade-in">
						<div className="flex-grow overflow-y-auto flex-center">
							<ExpenseAttachments runningOCR={RunningOcr} attachments={Attachments} updateAttachments={setAttachments} />
						</div>
						<Form onSubmit={onSubmit} className="bg-canvas border-l flex flex-col justify-between h-full w-full max-w-[25%] min-w-min">
							<div className="flex-1 overflow-x-hidden overflow-y-auto">
								<div className="sticky top-0 z-10 p-4 flex-center-between bg-canvas">
									<h3 className="align-center gap-x-2">
										<TbReceipt />
										Add Receipt
									</h3>
									<CloseButton onClick={() => setShow(false)} size="2xl" />
								</div>
								<Divider className="!border-light" />
								<div className="p-4">
									<h4>Expense Info</h4>
									<Input value={Values.merchant} onChange={(merchant) => updateValues({ merchant })} required className="mt-4" label="Merchant" placeholder="Merchant Name" />
									<Input min={0} value={Values.amount} onChange={(amount) => updateValues({ amount: Number(amount) })} required type="number" label="Transaction Amount" placeholder="Enter Amount" className="mt-4" />
									<DatePicker maxDate={moment().valueOf()} backDateSelection required date={Values.date} setDate={(date) => updateValues({ date })} label="Transaction Date" className="mt-4" />
									{isEnabled("ORGANIZATION") && (
										<Select
											loading={loading}
											options={response.categories.map((c) => ({ value: c.id, label: c.name }))}
											selected={Values.category}
											onChange={(category) => updateValues({ category })}
											required
											className="mt-4"
											btnClass="w-full"
											placeholder="Select Category"
											label="Category"
										/>
									)}
									<Input value={Values.description} onChange={(description) => updateValues({ description })} required className="mt-4" label="Description" placeholder="Explain the purpose of expense" />
								</div>
								{Values.category?.value && (
									<div className="p-4">
										<CustomFields className="mt-4" categoryId={Values.category?.value} getCustomFields={GET_CUSTOM_FIELDS} activity="EXPENSE" />
									</div>
								)}
							</div>
							<div className="p-4">
								<Button type="submit" variant="primary" className="w-full" loading={Loading}>
									Create Expense
								</Button>
							</div>
						</Form>
					</div>
				</Portal>
			)}
		</>
	);
}

export default AddReceipt;
